<template>
  <div>
    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
    <v-card class="my-4 elevation-25">
      <v-card-text>
        <v-row>
          <v-col class="py-1 pr-4">
            <v-avatar width="140" height="140" class="ml-3" :class="$vuetify.rtl ? 'float-right' : 'float-left'">
              <img src='@/assets/avatar-default.png'/>
              <!--                <img
                                  style="object-fit: cover"
                                  :src="imgUrl"
                                  alt="avatar"
                              >-->
            </v-avatar>
            <v-item :class="$vuetify.rtl ? 'float-right' : 'float-left'">
              <v-list-item>
                <v-list-item-content class="pt-0">
                  <h3>{{ client.name }} {{ client.family }}</h3>
                  <span class="pt-3 font-weight-light text-gray lighten-4">{{$t('$vuetify.counselorClientList.patient')}}</span>
                </v-list-item-content>
              </v-list-item >
            </v-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-tabs v-model="tabs">
      <v-tab href="#tab-1">{{$t('$vuetify.counselorClientList.UserInformation')}}</v-tab>
      <v-tab href="#tab-2">{{$t('$vuetify.counselorClientList.cycles')}}</v-tab>
      <v-tab href="#tab-3">{{$t('$vuetify.ClientNavItem.sessions')}}</v-tab>
      <v-tabs-slider></v-tabs-slider>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item value="tab-1">
        <v-card class="elevation-25">
          <v-card-text>
            <v-row>
              <v-col>
                <div>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="grey--text">{{
                          $vuetify.lang.t('$vuetify.profileFirstname') }}:
                        <strong class="black--text">{{ client.name }}</strong></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="grey--text">{{
                          $vuetify.lang.t('$vuetify.profileLastname') }}:
                        <strong class="black--text">{{ client.family }}</strong></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="grey--text">{{ $vuetify.lang.t('$vuetify.profileAge')
                        }}: <strong
                            class="black--text">{{ client.age }}</strong></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>

              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="grey--text">{{
                        $vuetify.lang.t('$vuetify.profileCountry') }}:
                      <strong class="black--text">{{ client.country }}</strong>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line v-if="client.hasOwnProperty('marital_state')">
                  <v-list-item-content>
                    <v-list-item-title class="grey--text">
                      {{ $vuetify.lang.t('$vuetify.profileCivilStatus') }}: <strong
                        class="black--text">{{
                        client.married ? 'متاهل' : 'مجرد' }}</strong></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-card class="pa-8">
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel
                  v-for="cycle in cycles" :key="cycle.id">
                <v-expansion-panel-header>
                  {{$t('$vuetify.counselorClientList.Topic')}} : {{$vuetify.rtl ? cycle.heading : showCycleHeadingInEn(cycle.heading) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    {{$t('$vuetify.counselorClientList.desc')}}
                    :</div>
                    {{cycle.problem_description}}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-card class="elevation-0 main-container">
          <v-card-text v-if="sessions.length">
            <v-row justify="right">
              <v-col cols="12" sm="8" md="6" lg="4" v-for="(session,index) in sessions" :key="index">
                <SessionCard :mode="'doctor'" :factor="false" :session="session"></SessionCard>
              </v-col>
            </v-row>

            <div class="text-center">
              <v-pagination
                  v-model="page"
                  :length="total"
              ></v-pagination>
            </div>
          </v-card-text>
          <v-card-text class="text-center" v-else>
            {{$t('$vuetify.sessionsPage.noSession')}}
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import SessionCard from '@/components/sessions/SessionCard';

export default {
  components: {
    SessionCard
  },
  created() {
    this.getClientSessions();
  },
  props: {
    client: Object,
    cycles: {
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      tabs: 'tab-1',
      sessions: [],
      page: 1,
      total: 1,
      items: [
        {
          text: this.$t('$vuetify.dashboard'),
          disabled: false,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/dashboard`,
        },
        {
          text: this.$t('$vuetify.navClientsLabel'),
          disabled: false,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/clients`,
        },
        {
          text: this.$t('$vuetify.ClientNavItem.userInformation'),
          disabled: true,
          to: 'clients',
        }
      ]
    }
  },
  methods: {
    getClientSessions() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getCounselorClientSessions', {
        clientId: this.client.id,
        page: this.page
      }).then((resp) => {
        this.total = resp.data.pagination.total_pages;
        this.sessions = resp.data.sessions;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },
    showCycleHeadingInEn(input){
      switch (input){
        case 'فردی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[0]')
          break;
        case 'زوج':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[1]')
          break;
        case 'خانواده':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[2]')
          break;
        case 'کودک و نوجوان':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[3]')
          break;
        case 'سکس تراپی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[4]')
          break;
        case 'روانپزشکی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[5]')
          break;
      }
      return input
    },

  },
  watch: {
    page() {
      this.getClientSessions();
    },
  }
}
</script>

<style>

</style>